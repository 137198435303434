import React, { useEffect, useRef, useState } from 'react';

import { MultiSelect } from 'atoms';
import { videoType } from 'API/hooks/useFetchVideos/types';

import styles from './VideoReady.module.scss';

const camerasLabelsMap = {
  left: 'Left',
  right: 'Right',
  front: 'Front',
  back: 'Back',
  front_left: 'Front Left',
  front_right: 'Front Right',
  rear_left: 'Rear Left',
  rear_right: 'Rear Right',
};

export const VideoReady = ({ video }) => {
  const cameras = video.storeItems.map((item) => ({
    value: item.cameraId,
    label: camerasLabelsMap[item.cameraLabel],
    video: item,
  }));

  const [firstDropdownOption, setFirstDropdownOption] = useState(cameras[0]);
  const [secondDropdownOption, setSecondDropdownOption] = useState(cameras[1]);

  const video1Ref = useRef(null);
  const video2Ref = useRef(null);

  const handlePlay = () => {
    video1Ref.current.play();
    video2Ref.current.play();
  };

  const handlePause = () => {
    video1Ref.current.pause();
    video2Ref.current.pause();
  };

  useEffect(() => {
    video1Ref.current.load();
  }, [firstDropdownOption.video.url]);

  useEffect(() => {
    video2Ref.current.load();
  }, [secondDropdownOption.video.url]);

  return (
    <>
      <div className="d-flex flex-column">
        <MultiSelect
          currentValues={firstDropdownOption}
          initialValues={firstDropdownOption}
          options={cameras}
          onChange={(selectedValues) => setFirstDropdownOption(selectedValues)}
          configOptions={{
            isMulti: false,
            closeMenuOnSelect: true,
            isSearchable: true,
          }}
          wrapperClassName="mb-2"
        />
        <video
          controls
          className={styles.video}
          ref={video1Ref}
          onPlay={handlePlay}
          onPause={handlePause}
          onError={() => console.error(`Error loading: ${firstDropdownOption.video.url}`)}
        >
          <source src={firstDropdownOption.video.url} type="video/mp4" />
        </video>
      </div>
      <div className="d-flex flex-column">
        <MultiSelect
          currentValues={secondDropdownOption}
          initialValues={secondDropdownOption}
          options={cameras}
          onChange={(selectedValues) => setSecondDropdownOption(selectedValues)}
          configOptions={{
            isMulti: false,
            closeMenuOnSelect: true,
            isSearchable: true,
          }}
          wrapperClassName="mb-2"
        />
        <video
          controls
          className={styles.video}
          ref={video2Ref}
          onPlay={handlePlay}
          onPause={handlePause}
          onError={() => console.error(`Error loading: ${secondDropdownOption.video.url}`)}
        >
          <source src={secondDropdownOption.video.url} type="video/mp4" />
        </video>
      </div>
    </>
  );
};

VideoReady.propTypes = {
  video: videoType,
};
