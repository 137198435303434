import { shape, number, string, bool, arrayOf, oneOfType, instanceOf } from 'prop-types';

import {
  projectType,
  worksiteType,
  fetchedPeriodType,
  notesType,
  dateFormatType,
} from '../../types';
import { locationType } from '../useFetchIncursions/types';
import { singleChartDataType } from '../useFetchInsights/types';

export const incursionsDataType = shape({
  totalIncursionsCount: number,
  acknowledgedIncursionsCount: number,
  acknowledgedPercentage: number,
  unacknowledgedIncursionsCount: number,
  operatorsCount: number,
});

export const baseStationType = shape({
  id: oneOfType([string, number]),
  manufacturer: string,
  model: string,
  owner: string,
  serialNumber: string,
  name: string,
  numberPlate: string,
  connectivityModuleId: string,
  // optional
  dvrId: string,
  online: bool,
});

export const heatmapDataType = arrayOf(
  shape({
    id: oneOfType([string, number]),
    location: locationType,
  }),
);

const chartDataType = shape({
  acknowledgedIncursions: singleChartDataType,
  unacknowledgedIncursions: singleChartDataType,
});

export const locationsType = arrayOf(
  shape({
    lat: number,
    lng: number,
    timestamp: oneOfType([string, instanceOf(Date)]),
  }),
);

export const baseStationFetchedDataType = shape({
  projects: arrayOf(projectType),
  sites: arrayOf(worksiteType),
  period: fetchedPeriodType,
  incursionsData: incursionsDataType,
  baseStation: baseStationType,
  heatmapData: heatmapDataType,
  chartData: chartDataType,
  locations: locationsType,
  notes: notesType,
  userName: string,
  dateFormat: dateFormatType,
  isVisionOnlyMode: bool,
});
