/* eslint-disable jsx-a11y/control-has-associated-label, jsx-a11y/label-has-for */

import React from 'react';

import { Filters, Input, SwitcherWithTooltip, NavSpinner, Modal, Scanner } from '../../../atoms';
import { peopleFetchedDataType, tagSchemaType } from '../../../API/hooks/useFetchEntities/types';
import { TableSection } from '../components/TableSection';
import { useEntities } from '../useEntities';
import styles from '../Entities.module.scss';

const People = ({ initialData: { people } }) => {
  const {
    currentProjects,
    setCurrentProjects,
    currentSites,
    setCurrentSites,
    period,
    setPeriod,
    dateFormat,
    searchText,
    setSearchText,
    projectOptions,
    siteOptions,
    entities,
    setShowInactive,
    showInactive,
    showModal,
    handleClose,
    handleShow,
    revalidateEntities,
    totalPages,
    totalCount,
    paginationParams,
    setPaginationParams,
    sortingParams,
    handleTableHeaderClick,
    fetchEntitiesIsLoading,
  } = useEntities(people, tagSchemaType);

  const onClickAway = (event) => {
    event.preventDefault();
    if (event.target === event.currentTarget) {
      handleClose();
    }
  };

  const onNewScanResult = (decodedText) => {
    handleClose();
    setSearchText(decodedText);
  };

  return (
    <div className={styles.page}>
      {fetchEntitiesIsLoading && <NavSpinner />}
      <Filters
        source="entities"
        period={period}
        setPeriod={setPeriod}
        dateFormat={dateFormat}
        projectOptions={projectOptions}
        siteOptions={siteOptions}
        currentProjects={currentProjects}
        setCurrentProjects={setCurrentProjects}
        currentSites={currentSites}
        setCurrentSites={setCurrentSites}
      >
        <div className={styles.inputWrapper}>
          <Input
            className={styles.inputField}
            value={searchText}
            onChange={setSearchText}
            placeholder="Search (ID or label)"
          />
          <button type="button" className={styles.inputButton} onClick={handleShow} />
        </div>
      </Filters>

      <Modal
        isOpen={showModal}
        onClose={handleClose}
        onClickAway={onClickAway}
        className={styles.modalScanner}
      >
        <Scanner fps={10} qrbox={250} disableFlip={false} qrCodeSuccessCallback={onNewScanResult} />
      </Modal>

      <SwitcherWithTooltip
        onChange={setShowInactive}
        checked={showInactive}
        labelText="Display tags that have not been tested or involved in an incursions in the period"
        switcherId="inactive-people"
      />
      <TableSection
        source="people"
        entities={entities}
        revalidateEntities={revalidateEntities}
        paginationParams={paginationParams}
        setPaginationParams={setPaginationParams}
        totalPages={totalPages}
        totalCount={totalCount}
        sortingParams={sortingParams}
        handleTableHeaderClick={handleTableHeaderClick}
      />
    </div>
  );
};

export default People;

People.propTypes = {
  initialData: peopleFetchedDataType,
};
