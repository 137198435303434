import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['projectSelect', 'siteSelect'];

  loadSites() {
    const projectId = this.projectSelectTarget.value;

    if (!projectId) {
      this.siteSelectTarget.innerHTML = "<option value=''>Please select a project first</option>";
      return;
    }

    this.siteSelectTarget.innerHTML = "<option value=''>Loading...</option>";

    fetch(`/api/v1/projects/worksites?project_id=${projectId}`)
      .then((response) => response.json())
      .then((data) => {
        this.siteSelectTarget.innerHTML = "<option value=''></option>";
        data.forEach((site) => {
          const option = document.createElement('option');
          option.value = site.id;
          option.textContent = site.name;
          this.siteSelectTarget.appendChild(option);
        });
      })
      .catch((error) => {
        console.error('Error loading worksites:', error);
        this.siteSelectTarget.innerHTML = "<option value=''>Failed to load sites</option>";
      });
  }
}
