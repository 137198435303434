import { shape, number, string, arrayOf, instanceOf, oneOfType, oneOf, bool } from 'prop-types';

import { projectType, worksiteType, fetchedPeriodType, dateFormatType } from '../../types';

export const tagSchemaType = ['Tag'];
export const machineSchemaType = ['Machine', 'Tester'];

export const machineKindType = shape({
  key: string,
  name: string,
});

export const coordinatesType = shape({
  lat: number,
  lng: number,
});

export const tagSchema = shape({
  id: oneOfType([string, number]),
  type: oneOf(tagSchemaType),
  label: string,
  totalIncursionsCount: number,
  acknowledgedIncursionsCount: number,
  unacknowledgedIncursionsCount: number,
  lastActivity: oneOfType([string, instanceOf(Date)]),
  lastActivityFormatted: string,
  editPath: string,
  showPath: string,
  slug: string,
});

export const machineSchema = shape({
  acknowledgedIncursionsCount: number,
  acknowledgementScore: number,
  active: bool,
  archivePath: string,
  archived: bool,
  editPath: string,
  id: oneOfType([string, number]),
  kind: string,
  label: string,
  lastActivity: oneOfType([string, instanceOf(Date)]),
  lastActivityFormatted: string,
  lastLocationCoordinates: coordinatesType,
  showPath: string,
  slug: string,
  totalIncursionsCount: number,
  type: oneOf(machineSchemaType),
  unacknowledgedIncursionsCount: number,
});

export const paginationSchema = shape({
  currentPage: number,
  totalPages: number,
  currentPerPage: number,
  firstPage: bool,
  lastPage: bool,
  totalCount: number,
});

export const peopleFetchedDataType = shape({
  people: shape({
    projects: arrayOf(projectType),
    sites: arrayOf(worksiteType),
    period: fetchedPeriodType,
    entitiesList: arrayOf(tagSchema),
    dateFormat: dateFormatType,
    pagination: paginationSchema,
  }),
});

export const machinesFetchedDataType = shape({
  machines: shape({
    projects: arrayOf(projectType),
    sites: arrayOf(worksiteType),
    period: fetchedPeriodType,
    entitiesList: arrayOf(machineSchema),
    dateFormat: dateFormatType,
    pagination: paginationSchema,
    availableKinds: arrayOf(machineKindType),
  }),
  isVisionOnlyMode: bool,
});

export const entitiesListType = arrayOf(oneOfType([tagSchema, machineSchema]));
