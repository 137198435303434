import React, { useMemo } from 'react';
import { oneOf, func, number, shape, string, bool } from 'prop-types';

import { entitiesListType } from '../../../../API/hooks/useFetchEntities/types';
import { TableControlledPagination } from '../../../../atoms';
import { sortingParamsSchema } from '../../types';
import { ENTITIES_SIZE_PER_PAGE } from '../../consts';

import { IdCell } from './IdCell/IdCell';
import { EditEntityCell } from './EditEntityCell/EditEntityCell';
import { ShowEntityCell } from './ShowEntityCell/ShowEntityCell';
import { InternalIdCell } from './InternalIdCell/InternalIdCell';
import { ArchiveOrUnarchiveEntityCell } from './ArchiveOrUnarchiveEntityCell';
import { IncursionsCell } from './IncursionsCell';
import { MachineKindCell } from './MachineKindCell/MachineKindCell';
import { LastActivityFormattedCell } from './LastActivityFormattedCell/LastActivityFormattedCell';
import { AcknowledgeScoreCell } from './AcknowledgeScoreCell';

const machineColumns = [
  {
    Header: '',
    accessor: 'id',
    Cell: MachineKindCell,
  },
  {
    Header: '',
    accessor: 'label',
    Cell: InternalIdCell,
  },
  {
    Header: 'Total Incursions',
    accessor: 'totalIncursionsCount',
    Cell: IncursionsCell,
  },
  {
    Header: 'Acknowledge Score\u00A0(%)',
    accessor: 'acknowledgementScore',
    Cell: AcknowledgeScoreCell,
  },
  {
    Header: 'Last Active',
    accessor: 'lastActivityFormatted',
    Cell: LastActivityFormattedCell,
  },
  { Header: '', accessor: 'showPath', Cell: ShowEntityCell },
  { Header: '', accessor: 'editPath', Cell: EditEntityCell },
  {
    Header: '',
    id: 'archive',
    accessor: (row) => row.archivePath,
    Cell: ArchiveOrUnarchiveEntityCell,
  },
];

const defaultColumns = [
  {
    Header: '',
    accessor: 'id',
    Cell: IdCell,
  },
  {
    Header: 'Internal ID',
    accessor: 'label',
    Cell: InternalIdCell,
  },
  {
    Header: 'Total Incursions',
    accessor: 'totalIncursionsCount',
    Cell: IncursionsCell,
  },
  {
    Header: 'Acknowledged Incursions',
    accessor: 'acknowledgedIncursionsCount',
    Cell: IncursionsCell,
  },
  {
    Header: 'Unacknowledged Incursions',
    accessor: 'unacknowledgedIncursionsCount',
    Cell: IncursionsCell,
  },
  {
    Header: 'Last Tag Test',
    accessor: 'lastActivityFormatted',
    Cell: LastActivityFormattedCell,
  },
  { Header: '', accessor: 'showPath', Cell: ShowEntityCell },
  { Header: '', accessor: 'editPath', Cell: EditEntityCell },
  {
    Header: '',
    id: 'archive',
    accessor: (row) => row.archivePath,
    Cell: ArchiveOrUnarchiveEntityCell,
  },
];

export const TableSection = ({
  source,
  entities,
  revalidateEntities,
  paginationParams,
  setPaginationParams,
  totalPages,
  totalCount,
  sortingParams,
  handleTableHeaderClick,
  tableHeaderClassName,
  isVisionOnlyMode,
}) => {
  const tableData = useMemo(() => entities, [entities]);

  const columns = useMemo(() => {
    if (source === 'machines') {
      if (isVisionOnlyMode) {
        const newMachineColumns = [...machineColumns];
        newMachineColumns.splice(2, 2);

        return newMachineColumns;
      }

      return machineColumns;
    }

    return defaultColumns;
  }, [source]);

  return (
    <TableControlledPagination
      columns={columns}
      data={tableData}
      initialPaginationState={paginationParams}
      setPaginationParams={setPaginationParams}
      pageCount={totalPages}
      totalCount={totalCount}
      pageSizeOptions={ENTITIES_SIZE_PER_PAGE}
      sortingParams={sortingParams}
      handleTableHeaderClick={handleTableHeaderClick}
      headerClassName={tableHeaderClassName}
      customCellProps={{ revalidateEntities }}
      isFirstColumnDark={source === 'machines'}
    />
  );
};

TableSection.propTypes = {
  source: oneOf(['machines', 'people']),
  entities: entitiesListType,
  paginationParams: shape({
    currentPage: number,
    perPage: number,
  }),
  setPaginationParams: func,
  totalPages: number,
  totalCount: number,
  sortingParams: sortingParamsSchema,
  handleTableHeaderClick: func,
  tableHeaderClassName: string,
  revalidateEntities: func,
  isVisionOnlyMode: bool,
};
